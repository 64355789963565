/* ************************************************************************************************************
                                    Font
************************************************************************************************************** */
@font-face {
  font-family: 'edenLight';
  font-display: swap;
  src: local('edenLight'),
    url('../fonts/edenFonts/GalanoGrotesque-Light.WOFF') format('woff');
}
@font-face {
  font-family: 'edenRegular';
  font-display: swap;
  src: local('edenRegular'),
    url('../fonts/edenFonts/GalanoGrotesque-Regular.WOFF') format('woff');
}
@font-face {
  font-family: edenMedium;
  font-display: swap;
  src: url('../fonts/edenFonts/GalanoGrotesqueAlt-Medium.WOFF') format('woff');
}
@font-face {
  font-family: edenSemiBold;
  font-display: swap;
  src: url('../fonts/edenFonts/GalanoGrotesqueAlt-SemiBold.WOFF') format('woff');
}
@font-face {
  font-family: edenBold;
  font-display: swap;
  src: url('../fonts/edenFonts/GalanoGrotesque-Bold.WOFF') format('woff');
}
/* ************************************************************************************************************
************************************************************************************************************** */
/* This css is used for general styling */
body {
  padding: 0;
  margin: 0;
}
/* div {
  position: relative;
} */
button,
a {
  cursor: pointer;
  font-family: edenRegular;
}
button:focus,
a:focus,
input:focus,
div:focus,
li:focus {
  outline: none !important;
}
.hideImm {
  opacity: 0;
}
.showImm {
  opacity: 1;
}
.mainHolder {
  width: 90%;
  margin: auto;
  flex-flow: column;
  display: flex;
}
.noMargin {
  margin: 0 !important;
}
/*  loading  */
.Loadingholder {
  width: 100%;
  height: 100%;
  max-width: 100%;
  /* max-height: 100%;  */
  position: absolute;
  min-height: 100px;
  /* min-width: 200px; */
  background-color: transparent;
  display: flex;
  z-index: 2;
}
.simpleLoading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.simpleLoading ul {
  display: flex;
  flex-flow: row;
  list-style: none;
  padding: 0;
}
.simpleLoading ul li {
  width: 14px !important;
  height: 14px !important;
  background-color: #fff;
  margin: 5px;
  border-radius: 50%;
  animation: load 1s linear alternate infinite;
}
.simpleLoading ul li:nth-child(1) {
  background-color: rgb(30, 69, 133);
}
.simpleLoading ul li:nth-child(2) {
  background-color: rgb(255, 186, 74);
  animation-delay: 0.2s;
}
.simpleLoading ul li:nth-child(3) {
  background-color: rgb(128, 199, 227);
  animation-delay: 0.4s;
}
.simpleLoading ul li:nth-child(4) {
  background-color: #f47525;
  animation-delay: 0.6s;
}
.simpleLoading ul li:nth-child(5) {
  background-color: rgb(43, 198, 127);
  animation-delay: 0.8s;
}
/* scroll design */
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: #000;
  /* border-radius: 10px; */
}
/* swal style */
.swal2-modal,
.swal2-modal p,
.swal2-modal ul,
.swal2-modal h2,
.swal2-modal #swal2-content,
.swal2-modal button {
  font-family: edenRegular;
}
/* modal body styling effect  */
.modal-open {
  overflow: hidden;
}
.modal {
  width: 60%;
  margin: auto;
  overflow-y: scroll;
  height: 90%;
  border-radius: 20px;
}
/* hide controls ios */
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}
/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}
/* New shadow dom for play button */
/* This one works! */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
/* animation */
@keyframes load {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
/*  responsive  */
@media only screen and (max-width: 700px) {
  .modal {
    width: 90%;
  }
}
